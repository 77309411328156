import { Team } from "@fscrypto/domain/team";
import { User } from "@fscrypto/domain/user";
import type { WorkItem } from "@fscrypto/domain/work-item";
import { Avatar, Button, Text } from "@fscrypto/ui";
import { AlertTriangleIcon, FolderIcon } from "lucide-react";
import { useCurrentUser } from "~/features/current-user";
import { tracking } from "~/utils/tracking";

interface FooterProps {
  copyWorkItem: () => void;
  selectedCollection: WorkItem | null;
  currentCollection: WorkItem | null;
  isMoveDisabled: boolean;
  isLoading: boolean;
  context: Team | User;
  currentTypename: WorkItem["typename"] | null;
}

const Footer = ({
  copyWorkItem,
  selectedCollection,
  currentCollection,
  isMoveDisabled,
  isLoading,
  context,
  currentTypename,
}: FooterProps) => {
  const nameOrUsername = "name" in context ? context.name : context.username;
  const { userPlan } = useCurrentUser();
  return (
    <div className="dark:border-gray-80 flex flex-wrap items-center justify-between border-t p-2">
      <div>
        {currentTypename === "dashboard" && (
          <div className="mb-1 flex items-center">
            <AlertTriangleIcon className="text-warning mr-3 h-4 w-4" />

            <Text size="xs" color="warning">
              All queries associated to this dashboard will also be copied over
            </Text>
          </div>
        )}
        <div className="flex items-center justify-between">
          {!isMoveDisabled && !isLoading ? (
            <MovingToLocation
              avatarUrl={context.avatarUrl}
              name={(selectedCollection?.name || currentCollection?.name) ?? null}
              contextName={nameOrUsername}
            />
          ) : (
            <div></div>
          )}
        </div>
      </div>
      <Button
        variant="primary"
        size="sm"
        onClick={() => {
          copyWorkItem();
          currentTypename === "dashboard" && tracking("dashboard_editor_copy_to_workspace", "Dashboard Beta");
          currentTypename === "query" && tracking("editor_copy_to_workspace", "Dashboard Beta");
          !isMoveDisabled && close();
        }}
        disabled={isLoading || isMoveDisabled}
      >
        Copy {currentTypename}
      </Button>
      {userPlan?.privacyEnabled && (
        <div className="mt-2 flex w-full items-center">
          <AlertTriangleIcon className="text-warning mr-3 h-4 w-4" />
          <Text size="xs" color="warning">
            Moving this file to another workspace on a Free plan will change its share settings to “Public”.
          </Text>
        </div>
      )}
    </div>
  );
};

export default Footer;

const MovingToLocation = ({
  name,
  contextName,
  avatarUrl,
}: {
  name: string | null;
  contextName: string;
  avatarUrl?: string;
}) => {
  return (
    <div className="text-gray-70 dark:text-gray-40 overflow-hidden font-medium">
      <div className="flex items-center overflow-hidden">
        <p className="text-gray-40 dark:text-gray-20 mr-2 flex-shrink-0 text-sm font-medium">Copying To: </p>
        <div className="mx-2 flex">
          <Avatar src={avatarUrl} size="sm" />
        </div>
        {name ? (
          <>
            <FolderIcon className="text-gray-40 mr-1 h-5 w-5 flex-shrink-0" />
            <p className="text-gray-70 dark:text-gray-40 truncate text-sm">{name}</p>
          </>
        ) : (
          "Root"
        )}
      </div>
    </div>
  );
};
